export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73')
];

export const server_loads = [0,15,2,8,4,9,10,17,11];

export const dictionary = {
		"/(landingpage)": [~42,[15]],
		"/(landingpage)/(pages)/Tutorial": [43,[15]],
		"/(landingpage)/(pages)/aviso_privacidad": [44,[15]],
		"/(landingpage)/(pages)/blog/[post]/[id]": [~45,[15]],
		"/(landingpage)/(pages)/brokers": [46,[15]],
		"/(landingpage)/(pages)/catalogo_de_contratos": [47,[15,16]],
		"/(landingpage)/(pages)/catalogo_de_contratos/prueba_concepto_templates_dinamicos": [49,[15,16]],
		"/(landingpage)/(pages)/catalogo_de_contratos/[templateId]": [48,[15,16]],
		"/(app)/documentos": [~18,[2,3]],
		"/(app)/documentos/documento/crear": [~27,[2,3]],
		"/(app)/documentos/documento/crear/crear_con_template_acuerdo": [~28,[2,3]],
		"/(app)/documentos/documento/crear/crear_con_template_acuerdo/[templateId]": [~29,[2,3]],
		"/(app)/documentos/documento/crear/crear_documento_subiendo_pdf": [~30,[2,3]],
		"/(app)/documentos/documento/crear/crear_documento_vacio": [~31,[2,3]],
		"/(app)/documentos/documento/[documentId]/v1/esperando_firmas": [~21,[5,6]],
		"/(app)/documentos/documento/[documentId]/v1/firmando_pdf": [~22,[5,7]],
		"/(app)/documentos/documento/[documentId]/v1/recolectando_firmantes": [~23,[5]],
		"/(app)/documentos/documento/[documentId]/v1/sellos_pdf": [~24,[5]],
		"/(app)/documentos/documento/[documentId]/v1/todos_firmaron": [~25,[5]],
		"/(app)/documentos/documento/[documentId]/v1/view": [~26,[8]],
		"/(app)/documentos/documento/[documentId]/[deprecatedUrls]": [~20,[2,3,4]],
		"/(app)/documentos/template/[documentId]/edit": [32,[9]],
		"/(app)/documentos/[carpeta]": [19,[2,3]],
		"/(landingpage)/(pages)/features/firma_autografa": [50,[15]],
		"/(landingpage)/(pages)/features/firma_avanzada": [51,[15]],
		"/(landingpage)/(pages)/features/firma_digital_documentos": [52,[15]],
		"/(landingpage)/(pages)/features/gestion_documentos": [53,[15]],
		"/(landingpage)/(pages)/features/live_check": [54,[15]],
		"/(landingpage)/(pages)/features/nom_151": [55,[15]],
		"/(landingpage)/(pages)/features/registro_marca": [56,[15]],
		"/(landingpage)/(pages)/features/registros_auditoria": [57,[15]],
		"/(landingpage)/(pages)/features/sellos_tiempo_digital": [58,[15]],
		"/(landingpage)/(pages)/industrias/legal_notarias": [~59,[15]],
		"/(app)/onboarding": [33,[10]],
		"/(landingpage)/(pages)/precios": [60,[15,17]],
		"/(landingpage)/(pages)/precios/checkout": [61,[15,17]],
		"/(landingpage)/(pages)/precios/checkout/gracias/[membresia]": [62,[15,17]],
		"/(landingpage)/(pages)/prelanzamiento": [63,[15]],
		"/(landingpage)/(pages)/programa_referidos_para_ti": [65,[15]],
		"/(landingpage)/(pages)/programa_referidos": [64,[15]],
		"/(app)/referidos/admin": [34,[2,11]],
		"/(app)/referidos/felicidades": [35,[2,11]],
		"/(landingpage)/(pages)/registrate": [66,[15]],
		"/(landingpage)/(pages)/seguridad": [67,[15]],
		"/(app)/settings/facturacion": [36,[2,12,13]],
		"/(app)/settings/membresia": [37,[2,12,14]],
		"/(app)/settings/membresia/documentos_terminados": [38,[2,12,14]],
		"/(app)/settings/membresia/gracias/[membresia]": [39,[2]],
		"/(app)/settings/membresia/membresia_vencida": [40,[2,12,14]],
		"/(app)/settings/mi_cuenta": [41,[2,12]],
		"/(landingpage)/(pages)/signin": [68,[15]],
		"/(landingpage)/(pages)/signin/adminauth": [69,[15]],
		"/(landingpage)/(pages)/signin/correo_enviado": [70,[15]],
		"/(landingpage)/(pages)/signin/error": [~71,[15]],
		"/(landingpage)/(pages)/terminos_condiciones": [72,[15]],
		"/(landingpage)/(pages)/validador_documentos_pdf_firmas_digitales_allsign": [73,[15]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';